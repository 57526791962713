import React from "react";
import Slider from "react-slick";
import { CateImg1, CateImg2, CateImg3 } from "../../constant/Index";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import currency from "currency-formatter";

function SliderCardProduct() {
	let CardMap = [
		{
			id: 1,
			thumbnail: CateImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 2,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 3,
			thumbnail: CateImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 4,
			thumbnail: CateImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 5,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 6,
			thumbnail: CateImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 7,
			thumbnail: CateImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 8,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
	];
	const TestiSlider = {
		arrows: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
	};
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 mx-auto health_first_heading">
						<p className="Heading_pro">Vitamins & Supplements</p>
						<Slider {...TestiSlider}>
							{CardMap?.map((prodata, index) => (
								<div key={prodata?.id}>
									<div className="single-product-card-wrapper sliderCardSet">
										<Link to={`/product-detail/${prodata?.id}`}>
											<div className="single-producrt-img-wrapper">
												<figure>
													<img
														src={prodata?.thumbnail}
														className="img-fluid"
														alt=""
													/>
												</figure>
											</div>
											<div className="single-product-content-wrapper">
												<p>{prodata?.name}</p>
												<h5 className="single-product-name">
													{prodata?.ProdName}
												</h5>
												<div className="review-wrapper">
													{/* <ul>
														{prodata?.rating ? (
															<>
																{[
																	...Array(
																		parseInt(prodata?.rating || 0),
																	).keys(),
																].map((starrr) => {
																	return (
																		<li key={`star_${starrr}`}>
																			<i className="fa fa-star"></i>
																		</li>
																	);
																})}
																<li className="reviewsSize">(119 reviews)</li>
															</>
														) : (
															<>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
															</>
														)}
														<span className="total-reviews"></span>
													</ul> */}
													<ul>
														{prodata?.rating.length > 0 ? (
															<>
																{prodata?.rating?.map((items, index) => {
																	return (
																		<Rating
																			key={index}
																			size={18}
																			readonly={true}
																			ratingValue={
																				Math.round(items[0]?.average) === 1
																					? "20"
																					: Math.round(items?.average) === 2
																					? "40"
																					: Math.round(items?.average) === 3
																					? "60"
																					: Math.round(items?.average) == 4
																					? "80"
																					: Math.round(items?.average) === 5
																					? "100"
																					: null
																			}
																		/>
																	);
																})}
																<li className="reviewsSize">
																	({prodata?.reviews_count} reviews)
																</li>
															</>
														) : (
															<>
																<Rating size={18} readonly={true} />
																<li className="reviewsSize">
																	({prodata?.reviews_count} reviews)
																</li>
															</>
														)}
														<span className="total-reviews"></span>
													</ul>
												</div>
												<div className="price-wrapper">
													{/* <h6 className="price">
														£{prodata?.unit_price}
														<span className="regular-price">
															£{prodata?.purchase_price}
														</span>
													</h6> */}
													<h6 className="price">
														{
															currency
																.format(prodata?.unit_price, {
																	code: "GBP",
																})
																.split(".00")[0]
														}
														<span className="regular-price">
															{
																currency
																	.format(prodata?.purchase_price, {
																		code: "GBP",
																	})
																	.split(".")[0]
															}
														</span>
													</h6>
												</div>
												<div className="cart-button-wrapper">
													{prodata?.current_stock == 0 ? (
														<>
															<button
																type="button"
																className="btn form-control form-control2"
																style={{
																	background: "#1aac7a",
																	color: "white",
																	cursor: "not-allowed",
																}}
																disabled
															>
																<i className="fa fa-shopping-cart"></i>
																&nbsp; Add To Cart
															</button>
														</>
													) : (
														<>
															<button
																type="button"
																className="btn form-control form-control2"
															>
																<i className="fa fa-shopping-cart"></i>
																&nbsp; Add To Cart
															</button>
														</>
													)}
												</div>
											</div>
										</Link>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</div>
		</>
	);
}

export default SliderCardProduct;
