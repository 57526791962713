import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CateImg1, CateImg2, CateImg3 } from "../../constant/Index";
import { useDispatch, useSelector } from "react-redux";
import { SendCateNameLice } from "../../store/productSlice";
import { Rating } from "react-simple-star-rating";
import currency from "currency-formatter";

function HomeProductPage() {
	const homeCategory = useSelector((state) => state.product?.HomeCatData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let CardMap = [
		{
			id: 1,
			thumbnail: CateImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 2,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 3,
			thumbnail: CateImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 4,
			thumbnail: CateImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 5,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 6,
			thumbnail: CateImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
	];
	const HandleClickss = (names) => {
		dispatch(SendCateNameLice(names));
		navigate("/shop");
	};
	return (
		<>
			<section className="mainContainerCate">
				<div className="container mainContainerCate">
					<div className="row ">
						<div className="col-lg-3 ">
							<div className="first_section_heading">
								<div className="first_section_btn">
									<Link to="#">
										<i class="fa fa-times" style={{ color: "#fff" }}></i>
									</Link>
								</div>
								<div className="first_section_para">
									<h3 className="Cate">Categories</h3>
								</div>
							</div>
							<div className="first_section_category">
								<div id="accordion">
									<ul>
										<li>
											<div class="card">
												{homeCategory.map((itemsss, index) => {
													return (
														<>
															<div class="card-header" id="headingThree">
																<h5 class="mb-0">
																	<button
																		class="btn btn-link collapsed"
																		data-toggle="collapse"
																		data-target={"#collapse" + itemsss?.id}
																		aria-expanded="false"
																		aria-controls={"collapse" + itemsss?.id}
																	>
																		<i class="fa fa-plus"></i>
																		<h5
																			style={{ cursor: "pointer" }}
																			onClick={() =>
																				HandleClickss(itemsss?.name)
																			}
																		>
																			{itemsss?.name}
																		</h5>
																	</button>
																</h5>
															</div>
															<div
																id={"collapse" + itemsss?.id}
																class="collapse"
																aria-labelledby="headingThree"
																data-parent="#accordion"
															>
																{itemsss.childes.map((item) => (
																	<div
																		class="card-body"
																		style={{ cursor: "pointer" }}
																		onClick={() => HandleClickss(itemsss.name)}
																	>
																		{item.name}
																	</div>
																))}
															</div>
														</>
													);
												})}
											</div>
										</li>
										{/* <li>
											<span className="addIconCate">
												<i class="fa fa-plus"></i>
											</span>
											<span>
												<p className="addParaCate">Health & Wellness</p>
											</span>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-9 HomeCateCard">
							{CardMap?.map((prodata, index) => (
								<div key={prodata?.id} className="col-lg-4">
									<div className="single-product-card-wrapper double-product-card-wrapper">
										<Link to={`/product-detail/${prodata?.id}`}>
											<div className="single-producrt-img-wrapper">
												<figure>
													<img
														src={prodata?.thumbnail}
														className="img-fluid"
														alt=""
													/>
												</figure>
											</div>
											<div className="single-product-content-wrapper">
												<p>{prodata?.name}</p>
												<h5 className="single-product-name">
													{prodata?.ProdName}
												</h5>
												<div className="review-wrapper">
													{/* <ul>
														{prodata?.rating ? (
															<>
																{[
																	...Array(
																		parseInt(prodata?.rating || 0),
																	).keys(),
																].map((starrr) => {
																	return (
																		<>
																			<li key={`star_${starrr}`}>
																				<i className="fa fa-star"></i>
																			</li>
																		</>
																	);
																})}
																<li className="reviewsSize">(119 reviews)</li>
															</>
														) : (
															<>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
															</>
														)}
														<span className="total-reviews"></span>
													</ul> */}
													<ul>
														{prodata?.rating.length > 0 ? (
															<>
																{prodata?.rating?.map((items, index) => {
																	return (
																		<Rating
																			key={index}
																			size={18}
																			readonly={true}
																			ratingValue={
																				Math.round(items[0]?.average) === 1
																					? "20"
																					: Math.round(items?.average) === 2
																					? "40"
																					: Math.round(items?.average) === 3
																					? "60"
																					: Math.round(items?.average) == 4
																					? "80"
																					: Math.round(items?.average) === 5
																					? "100"
																					: null
																			}
																		/>
																	);
																})}
																<li className="reviewsSize">
																	({prodata?.reviews_count} reviews)
																</li>
															</>
														) : (
															<>
																<Rating size={18} readonly={true} />
																<li className="reviewsSize">
																	({prodata?.reviews_count} reviews)
																</li>
															</>
														)}
														<span className="total-reviews"></span>
													</ul>
												</div>
												<div className="price-wrapper">
													{/* <h6 className="price">
														£{prodata?.unit_price}
														<span className="regular-price">
															£{prodata?.purchase_price}
														</span>
													</h6> */}
													<h6 className="price">
														{
															currency
																.format(prodata?.unit_price, {
																	code: "GBP",
																})
																.split(".00")[0]
														}
														<span className="regular-price">
															{
																currency
																	.format(prodata?.purchase_price, {
																		code: "GBP",
																	})
																	.split(".")[0]
															}
														</span>
													</h6>
												</div>
												<div className="cart-button-wrapper">
													{prodata?.current_stock == 0 ? (
														<>
															<button
																type="button"
																className="btn form-control form-control2"
																style={{
																	background: "#1aac7a",
																	color: "white",
																	cursor: "not-allowed",
																}}
																disabled
															>
																<i className="fa fa-shopping-cart"></i>
																&nbsp; Add To Cart
															</button>
														</>
													) : (
														<>
															<button
																type="button"
																className="btn form-control form-control2"
															>
																<i className="fa fa-shopping-cart"></i>
																&nbsp; Add To Cart
															</button>
														</>
													)}
												</div>
											</div>
										</Link>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HomeProductPage;
