import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

let token = localStorage.getItem("Token");
if (token) {
	token = localStorage.getItem("Token").slice(1, -1);
}

// export const tokenFunc = () => {
//   let token = localStorage.getItem("Token");
//   if (token) {
//     token = localStorage.getItem("Token").slice(1, -1);
//   }
// }

export const STATUSES = Object.freeze({
	IDLE: "idle",
	LOADING: "loading",
	ERROR: "erro",
});

// Fetch Product Detail Api
export const fetchProductDetail = createAsyncThunk(
	"blog/detail",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/products/details/${arg}`,
			);
			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Sub Category
export const SubCategories = createAsyncThunk(
	"sub/categories",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/categories/subcategory/${arg}`,
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// All Category
export const HomeCategories = createAsyncThunk(
	"home/categories",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/categories",
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Fetch Product Category Api -----------------------------
export const fetchProductCategory = createAsyncThunk(
	"product/category",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/categories/all",
			);
			console.log(data, "sddssdds");
			return data.response.data;
		} catch (error) {
			console.log("dssdds545", error);
			rejectWithValue(error.response.data);
		}
	},
);

// -------------------------------------------------------

// Fetch Realted Product Api
export const fetchRelatedProduct = createAsyncThunk(
	"related/products",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/products/related-products/${arg}`,
			);
			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Fetch Discounted Product Api
export const fetchDiscountProduct = createAsyncThunk(
	"discounted/product",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/products/discount",
			);
			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Add Product Review Api
export const ProductComment = createAsyncThunk(
	"product/comment",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(
				"https://nutirents.developer-ourbase-camp.com/api/v1/products/reviews/submit",
				arg,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			toast.success("Review Submitted Successfully");
			return data;
		} catch (error) {
			error.response.data.errors.forEach((error) => {
				toast.error(error);
			});
			rejectWithValue(error.response.data);
		}
	},
);

// Fetch Product Reviews Api
export const fetchProductReview = createAsyncThunk(
	"product/review",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/products/reviews/${arg}`,
			);
			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Fetch Feature Product
export const fetchFeatureProduct = createAsyncThunk(
	"feature/product",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/products/featured",
			);
			return data.response.data.products;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Add wishlist
export const AddWishlist = createAsyncThunk(
	"product/wishlist",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.post(
				"https://nutirents.developer-ourbase-camp.com/api/v1/customer/wish-list/add",
				arg,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			toast.success(data?.message);
			return data?.response?.data;
		} catch (error) {
			rejectWithValue(error.response.data);
			if (error.message == "Request failed with status code 401") {
				toast.error("Please login");
			}
		}
	},
);

// Fetch Wishlist Product
export const fetchWishlist = createAsyncThunk(
	"wishlist/fetch",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/customer/wish-list",
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			return data.response.data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Delete Wishlist
export const deleteWishlist = createAsyncThunk(
	"wishlist/delete",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.delete(
				`https://nutirents.developer-ourbase-camp.com/api/v1/customer/wish-list/remove?product_id=${arg}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);

			toast.success(data?.message);
			return arg;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Serach Product Api
export const fetchProducts = createAsyncThunk(
	"product/fetchProducts",
	async (datas, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/products/all?offset=${
					datas?.page ? datas.page : ""
				}&limit=9&slug=${datas.name ? datas.name : ""}&from_price=${
					datas.from_price ? datas.from_price : 0
				}&to_price=${datas.to_price ? datas.to_price : 0}&rating=${
					datas.rating ? datas.rating : 0
				}&category_id=${
					datas.category_id ? datas.category_id : 0
				}&price_range=${datas.price_range ? datas.price_range : 0}&discount=${
					datas.discount ? datas.discount : 0
				}&cat_name=${datas.cat_name ? datas.cat_name : ""}`,
			);
			return data;
		} catch (error) {
			toast.error(error?.response?.data?.errors?.message);
			// rejectWithValue(error.response.data);
			return error?.response?.data;
		}
	},
);

// All Category
export const AllCategory = createAsyncThunk(
	"all/categories",
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/categories/all",
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Sub Category Product ***
export const subCatProduct = createAsyncThunk(
	"sub/product",
	async (arg, { rejectWithValue, dispatch }) => {
		dispatch(setStatus(STATUSES.LOADING));
		try {
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/categories/products/${arg}`,
			);
			sessionStorage.setItem(
				"searchData",
				JSON.stringify(data?.response?.data),
			);
			dispatch(setStatus(STATUSES.IDLE));
			return data;
		} catch (error) {
			dispatch(setStatus(STATUSES.ERROR));
			rejectWithValue(error.response.data);
		}
	},
);

/// Add Addresss

// /customer/address/add

export const AddAddress = createAsyncThunk(
	"add/newAddress",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.post(
				"https://nutirents.developer-ourbase-camp.com/api/v1/customer/address/add",
				arg,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			toast.success(data?.message);
			return data?.response?.data;
		} catch (error) {
			rejectWithValue(error.response.data);
			if (error.message == "Request failed with status code 401") {
				toast.error("Please login");
			}
		}
	},
);

//Get

// Get Coupon

export const GetCoupon = createAsyncThunk(
	"post/coupon",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.post(
				`https://nutirents.developer-ourbase-camp.com/api/v1/coupon/apply`,
				arg,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			return data;
		} catch (error) {
			error?.response?.data?.errors.forEach((err) => {
				toast.error(err.message);
			});
			rejectWithValue(error.response.data);
		}
	},
);

// Get All Address

export const getAddress = createAsyncThunk(
	"get/address",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.get(
				"https://nutirents.developer-ourbase-camp.com/api/v1/customer/address/list",
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Get Shipping Method;
// ?id=${
// 	arg?.id ? arg.id : ""
// }

export const getShippingMethod = createAsyncThunk(
	"get/ShippingMethod",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/products/shipping-methods?id=${
					arg ? arg.id : ""
				}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Strip Payment Api
export const SendTokenStrip = createAsyncThunk(
	"get/StripMethod",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.post(
				`https://nutirents.developer-ourbase-camp.com/api/v1/customer/order/place`,
				arg,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (data.message == "Order Placed Successfully") {
				toast.success(data.message);
				sessionStorage.setItem(
					"paymentStatus",
					JSON.stringify(data?.response?.data),
				);
			} else {
				toast.error(data.message);
			}
			return data;
		} catch (error) {
			error.response.data.errors.forEach((err) => {
				toast.error(err);
			});
			rejectWithValue(error.response.data);
		}
	},
);

// Get All Orders

export const getAllOrders = createAsyncThunk(
	"get/allOrders",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/customer/order/list?offset=${
					arg?.page ? arg.page : ""
				}&limit=${arg?.limit ? arg.limit : ""}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Get Order Details

export const getOrderDetails = createAsyncThunk(
	"get/orderDetails",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/customer/order/details?order_id=${
					arg ? arg.id : ""
				}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Add to cart

export const AddCart = createAsyncThunk(
	"get/Cart",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.post(
				`https://nutirents.developer-ourbase-camp.com/api/v1/cart/add`,
				arg,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			toast.success("Item Added to Cart");
			// localStorage.setItem(
			// 	"cartItems",
			// 	JSON.stringify(data?.response?.data),
			// );
			return data;
		} catch (error) {
			if (error.response.data.message !== "Unauthenticated.") {
				toast.error(error.response.data.message);
			} else {
				toast.error("Please Login to Add to Cart");
			}
			console.log(error.response.data.message);
			rejectWithValue(error.response.data);
		}
	},
);

//Get All Products

export const AddCartGetAll = createAsyncThunk(
	"get/AllOrderProducts",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			const { data } = await axios.get(
				`https://nutirents.developer-ourbase-camp.com/api/v1/cart`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			localStorage.setItem("cartItems", JSON.stringify(data?.response?.data));
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

//Update Cart Products

export const UpdatedCart = createAsyncThunk(
	"get/cartsUpdated",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			console.log(token);
			const { data } = await axios.post(
				`https://nutirents.developer-ourbase-camp.com/api/v1/cart/update`,
				arg,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			toast.success("Quantity Updated");
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// Delete item from cart

export const DeleteItem = createAsyncThunk(
	"get/cartsDeleted",
	async (arg, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("Token");
			if (token) {
				token = localStorage.getItem("Token").slice(1, -1);
			}
			console.log(token);
			const { data } = await axios.delete(
				`https://nutirents.developer-ourbase-camp.com/api/v1/cart/remove?key=${arg.key}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			return data;
		} catch (error) {
			rejectWithValue(error.response.data);
		}
	},
);

// /customer/address/list
let payStatus = sessionStorage.getItem("paymentStatus");
let SearchDta = sessionStorage.getItem("searchData");

const productSlice = createSlice({
	name: "product",
	initialState: {
		data: [],
		Feature: [],
		ProductDetail: [],
		ProductCategories: [],
		RelatedProduct: [],
		GetAddress: [],
		GetShippingAddress: [],
		DiscountProduct: [],
		ProductReviews: [],
		WishlistProduct: [],
		AllCategories: [],
		ProductData: [],
		HomeCatData: [],
		SubCategory: [],
		deliverId: [],
		purchaseOrder: [],
		orderDetails: [],
		Cart: localStorage.getItem("cartItems")
			? JSON.parse(localStorage.getItem("cartItems"))
			: [],
		UpdatedsCart: [],
		DeletedCart: [],
		AddData: [],
		discountCode: "",
		addressId: "",
		ProdesCode: "",
		NamesList: sessionStorage.getItem("innerName")
			? sessionStorage.getItem("innerName")
			: "",
		Catid: "",
		rating: "",
		isPageChange: false,
		mainSearvhFilter: "",
		priceFilter: [],
		paymentStatus: payStatus ? payStatus : "",
		SubData: SearchDta ? JSON.parse(SearchDta) : "0",
		statusCode: "",
		message: "",
		status: STATUSES.IDLE,
	},
	reducers: {
		// setProduct(state, action) {
		// 	state.data = action.payload.response.data;
		// },
		setStatus(state, action) {
			state.status = action.payload;
		},
		setDeliverId(state, action) {
			state.deliverId = action.payload;
		},
		setAddressId(state, action) {
			state.addressId = action.payload;
		},
		SendCatId(state, action) {
			state.Catid = action.payload;
		},
		SendRating(state, action) {
			state.rating = action.payload;
		},
		SendPriceFilter(state, action) {
			state.priceFilter = action.payload;
		},
		SendSearchVal(state, action) {
			state.mainSearvhFilter = action.payload;
		},
		SendDiscount(state, action) {
			state.discountCode = action.payload;
		},
		SendCateNameLice(state, action) {
			state.ProdesCode = action.payload;
		},
		SendCateNamess(state, action) {
			state.NamesList = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProductDetail.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchProductDetail.fulfilled, (state, action) => {
				state.isLoading = false;
				state.ProductDetail = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchProductDetail.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchProductCategory.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchProductCategory.fulfilled, (state, action) => {
				state.isLoading = false;
				state.ProductCategories = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchProductCategory.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchRelatedProduct.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchRelatedProduct.fulfilled, (state, action) => {
				state.isLoading = false;
				state.RelatedProduct = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchRelatedProduct.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchDiscountProduct.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchDiscountProduct.fulfilled, (state, action) => {
				state.isLoading = false;
				state.DiscountProduct = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchDiscountProduct.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchFeatureProduct.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchFeatureProduct.fulfilled, (state, action) => {
				state.isLoading = false;
				state.Feature = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchFeatureProduct.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(ProductComment.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(ProductComment.fulfilled, (state, action) => {
				state.isLoading = false;
				state.ProductReviews = action.payload?.response?.data;
				state.isSuccess = true;
			})
			.addCase(ProductComment.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchProductReview.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchProductReview.fulfilled, (state, action) => {
				state.isLoading = false;
				state.ProductReviews = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchProductReview.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchWishlist.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchWishlist.fulfilled, (state, action) => {
				state.isLoading = false;
				state.WishlistProduct = action.payload;
				state.isSuccess = true;
			})
			.addCase(fetchWishlist.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(AddWishlist.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(AddWishlist.fulfilled, (state, action) => {
				state.isLoading = false;
				state.WishlistProduct = action.payload;
				state.isSuccess = true;
			})
			.addCase(AddWishlist.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(deleteWishlist.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(deleteWishlist.fulfilled, (state, action) => {
				state.isLoading = false;
				state.WishlistProduct = state.WishlistProduct.filter(
					(val) => val?.product_id !== action.payload,
				);
				state.isSuccess = true;
			})
			.addCase(deleteWishlist.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(fetchProducts.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchProducts.fulfilled, (state, action) => {
				state.isLoading = false;
				state.ProductData = action.payload?.response?.data;
				state.isSuccess = true;
				state.message = action.payload?.errors?.message;
			})
			.addCase(fetchProducts.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(HomeCategories.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(HomeCategories.fulfilled, (state, action) => {
				state.isLoading = false;
				state.HomeCatData = action.payload?.response?.data;
				state.isSuccess = true;
			})
			.addCase(HomeCategories.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(SubCategories.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(SubCategories.fulfilled, (state, action) => {
				state.isLoading = false;
				state.SubCategory = action.payload?.response?.data;
				state.isSuccess = true;
			})
			.addCase(SubCategories.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(subCatProduct.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(subCatProduct.fulfilled, (state, action) => {
				state.isLoading = false;
				state.SubData = action.payload?.response?.data;
				state.isSuccess = true;
			})
			.addCase(subCatProduct.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(AllCategory.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(AllCategory.fulfilled, (state, action) => {
				state.isLoading = false;
				state.AllCategories = action.payload?.response?.data;
				state.isSuccess = true;
			})
			.addCase(AllCategory.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(AddAddress.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(AddAddress.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				// state.message = action.payload.data.message;
			})
			.addCase(AddAddress.rejected, (state, action) => {
				state.isLoading = false;
				// state.message = action.payload;
				state.isSuccess = false;
			})
			.addCase(GetCoupon.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(GetCoupon.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.Coupon = action.payload?.response?.data;
				state.message = action.payload?.message;
			})
			.addCase(GetCoupon.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
				state.isSuccess = false;
			})
			.addCase(getAddress.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getAddress.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.GetAddress = action.payload?.response?.data;
			})
			.addCase(getAddress.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
				state.isSuccess = false;
			})
			.addCase(getShippingMethod.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getShippingMethod.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.GetShippingAddress = action.payload?.response?.data;
			})
			.addCase(getShippingMethod.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
				state.isSuccess = false;
			})
			.addCase(SendTokenStrip.pending, (state) => {
				state.isLoading = true;
				state.isPageChange = false;
			})
			.addCase(SendTokenStrip.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isPageChange = true;
				state.message = action.payload?.message;
				state.paymentStatus = action.payload?.response?.data;
			})
			.addCase(SendTokenStrip.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
				state.isPageChange = false;
				state.isSuccess = false;
			})
			.addCase(getAllOrders.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getAllOrders.fulfilled, (state, action) => {
				state.isLoading = false;
				state.purchaseOrder = action.payload?.response?.data;
			})
			.addCase(getAllOrders.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
			})
			.addCase(getOrderDetails.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getOrderDetails.fulfilled, (state, action) => {
				state.isLoading = false;
				state.orderDetails = action.payload?.response?.data;
			})
			.addCase(getOrderDetails.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
			})
			.addCase(AddCartGetAll.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(AddCartGetAll.fulfilled, (state, action) => {
				state.isLoading = false;
				state.Cart = action.payload?.response?.data;
			})
			.addCase(AddCartGetAll.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
			})
			.addCase(AddCart.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(AddCart.fulfilled, (state, action) => {
				state.isLoading = false;
				state.AddData = action.payload?.response?.data;
			})
			.addCase(AddCart.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
			})
			.addCase(UpdatedCart.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(UpdatedCart.fulfilled, (state, action) => {
				state.isLoading = false;
				state.UpdatedsCart = action.payload?.response?.data;
			})
			.addCase(UpdatedCart.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
			})
			.addCase(DeleteItem.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(DeleteItem.fulfilled, (state, action) => {
				state.isLoading = false;
				state.DeletedCart = action.payload?.response?.data;
				console.log(action.payload?.response?.data, "sddsds");
			})
			.addCase(DeleteItem.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload?.message;
			});
	},
});

export const {
	setStatus,
	setDeliverId,
	setAddressId,
	SendCatId,
	SendRating,
	SendDiscount,
	SendPriceFilter,
	SendSearchVal,
	SendCateNameLice,
	SendCateNamess,
} = productSlice.actions;

export default productSlice.reducer;

// export function fetchProducts(data) {
// 	return async function fetchProductThunk(dispatch, getState) {
// 		dispatch(setStatus(STATUSES.LOADING));
// 		try {
// 			const res = await fetch(
// 				`https://nutirents.developer-ourbase-camp.com/api/v1/products/all?offset=${data?.page ? data.page : ""}&limit=9&slug=${data.name?data.name : ""}`,
// 			);
// 			const data = await res.json();
// 			dispatch(setProduct(data));
// 			dispatch(setStatus(STATUSES.IDLE));
// 			sessionStorage.removeItem("searchData");
// 		} catch (err) {
// 			console.log(err);
// 			dispatch(setStatus(STATUSES.ERROR));
// 		}
// 	};
// }
