import React, { useEffect } from "react";
import "../../assets/css/style.css";
import { Link, useNavigate } from "react-router-dom";
// Components Import
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import ProductCategory from "../../components/ProductCategory/ProductCategory";
import About from "../../components/About/About";
import Products from "../../components/Products/Products";
import ProductsBanner from "../../components/ProductBanner/ProductsBanner";
import SpecialOffers from "../../components/SpecialOffers/SpecialOffers";
import SpecialProduct from "../../components/SpecialProduct/SpecialProduct";
import Testimonial from "../../components/Testimonial/Testimonial";
import Blog from "../../components/Blog/Blog";
import FeatureProduct from "../../components/Products/FeatureProduct";
import SpecialOffers2 from "../../components/SpecialOffers/SpecialOffer2";
import {
	SendCateNameLice,
	SendCatId,
	SendDiscount,
	SendPriceFilter,
	SendSearchVal,
} from "../../store/productSlice";
import { useDispatch, useSelector } from "react-redux";
import HomeProductPage from "../../components/HomeProductPage/HomeProductPage";
import HealthWellness from "../../components/HomeProductPage/HealthWellness";
import SliderCardProduct from "../../components/HomeProductPage/SliderCardProduct";
import BestSeller from "../../components/HomeProductPage/BestSeller";
import {
	BestSellBg,
	Card1,
	CardImg2,
	CardImg3,
	CateImg2,
	BlogImg1,
	bestSeller2,
	medicies,
} from "../../constant/Index";
import FeaturePro from "../../components/Products/FeaturePro";
import FoodAndDrink from "../../components/HomeProductPage/FoodAndDrink";

const Home = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const HandleDiscount = () => {
		dispatch(SendDiscount(1));
		navigate("/shop");
	};

	useEffect(() => {
		dispatch(SendCatId(0));
		dispatch(SendPriceFilter([0, 0]));
		dispatch(SendSearchVal(null));
		dispatch(SendDiscount(0));
		dispatch(SendCateNameLice(""));
	}, []);
	return (
		<>
			<Header />

			{/* Banner Sec */}
						<Banner />
			{/* Banner Sec */}

			{/* Product Category Sec */}
			<section className="product-card some-css-for-upper">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="product-card-slider">
								<HomeProductPage />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Product Category Sec */}

			{/* About Sec */}
			{/* <About /> */}
			{/* About Sec */}
			<section className="Health-card ">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="product-card-slider">
								<HealthWellness />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="testimonails product-card">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="">
								<SliderCardProduct />
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Product Banners Sec */}
			<section className="Health-card">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="product-card-slider">
								<ProductsBanner />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Product Banners Sec */}

			{/* <section className="">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="product-card-slider">
								<BestSeller />
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* Feature Product Sec */}
			<section className="product-card">
				<div className="container">
					<div className="row pb-4">
						<div className="col-lg-12">
							<div className="content-wrapper text-center health_first_heading">
								<p className="Heading_pro">Herbs & Natural Remedies</p>
							</div>
						</div>
					</div>
					<div className="row single-product-card">
						{/* <FeatureProduct limit={8} /> */}
						<FeaturePro />
					</div>
				</div>
			</section>
			{/* Feature Product Sec */}

			{/* Special Product Sec */}
			{/* <section className="special-offer">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper text-center">
								<h2>Special Offer</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4">
							<SpecialOffers Prolimit={4} />
						</div>
						<div className="col-lg-4">
							<SpecialProduct />
						</div>
						<div className="col-lg-4">
							<SpecialOffers2 />
						</div>
						<div className="col-lg-12">
							<div className="button-group text-center">
								<button className="btn" onClick={HandleDiscount}>
									View More
								</button>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* Special Product Sec */}

			{/* Testimonial Sec */}
			{/* <section className="testimonails">
				<div className="container">
					<div className="row pb-4">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="content-wrapper text-center">
								<h2>Our Happy Customers</h2>
								<p>
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="testimonails-slider">
								<Testimonial />
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section> */}
			{/* Testimonial Sec */}

			<section className="testimonails ">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="">
								<FoodAndDrink />
							</div>
						</div>
					</div>
				</div>
			</section>

			{/*  */}
			<section className="best-seller product-card2">
				<div className="container  health_first_heading">
					<p className="Heading_pro">Best Seller</p>
					<div className="row">
						<div className="col-lg-6">
							<div className="product-box first-box">
								<div className="img-box frst-img">
									<figure>
										<img src={BestSellBg} alt="" className="img-fluid" />
									</figure>
								</div>
								<div className="off">
									<div className="off-box">
										<h3>50%</h3>
										<h3>Off</h3>
									</div>
									<div className="nutrient">
										<figure>
											<img src={CateImg2} alt="" className="img-fluid" />
										</figure>
									</div>
								</div>
								<div className="content-wrapper para-color">
									<h4>Buy 1 get 1 free</h4>
									<p className="for-para-color">Lorem Ipsum is simply dummy text</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="row">
								<div className="col-md-12">
									<div className="product-box second-box">
										<div className="img-box frst-img">
											<figure>
												<img src={Card1} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="off">
											<div className="off-box">
												<h3>30%</h3>
												<h3>Off</h3>
											</div>
											<div className="nutrient">
												<figure>
													<img src={CardImg2} alt="" className="img-fluid" />
												</figure>
											</div>
										</div>
										<div className="content-wrapper para-color">
											<h4>Buy 1 get 1 free</h4>
											<p className="for-para-color">Lorem Ipsum is simply dummy text</p>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="spacer"></div>
								</div>
								<div className="col-md-6">
									<div className="product-box third-box">
										<div className="img-box frst-img">
											<figure>
												<img src={bestSeller2} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="off">
											<div className="off-box">
												<h3>20%</h3>
												<h3>Off</h3>
											</div>
											<div className="nutrient">
												<figure>
													<img src={CardImg3} alt="" className="img-fluid" />
												</figure>
											</div>
										</div>
										<div className="content-wrapper para-color">
											<h4>Buy 1 get 1 free</h4>
											<p className="for-para-color">Lorem Ipsum is simply dummy text</p>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="product-box third-box for-img">
										<div className="img-box frst-img">
											<figure>
												<img src={BlogImg1} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="off">
											<div className="off-box off-box2">
												<h3>20%</h3>
												<h3>Off</h3>
											</div>
											<div className="nutrient">
												<figure>
													<img src={medicies} alt="" className="img-fluid" />
												</figure>
											</div>
										</div>
										<div className="content-wrapper para-color">
											<h4>Buy 1 get 1 free</h4>
											<p className="for-para-color">Lorem Ipsum is simply dummy text</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*  */}

			{/* Blog Sec */}
			<section className="blog product-card2">
				<div className="container">
					<div className="row pb-4">
						<div className="col-lg-12">
							<div className="content-wrapper text-center health_first_heading">
								<p className="Heading_pro">Blogs</p>
							</div>
						</div>
					</div>
					<div className="row blog-row-wrapper">
						<Blog limit={3} />
					</div>
				</div>
			</section>
			{/* Blog Sec */}

			<Footer />
		</>
	);
};

export default Home;
